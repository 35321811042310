import React, {
  useState,
  useEffect,
  useContext,
  ReactNode,
  createContext,
} from 'react'
import ReactGA from 'react-ga' // eslint-disable-line
import Cookies, { CookieAttributes } from 'js-cookie'
import CookieBanner from './components/layout/CookieBanner'
import { loadScript } from './utils/scriptLoader'
import useApi from './templates/useApi'
import { isBrowser } from './react-utils/dom'
import { PAYPAL_THEME } from '@paypalcorp/pp-react'
import { ThemeProvider } from '@emotion/react'
import { config } from './providerConfig'

const PP_SDK_URL = 'https://www.paypal.com/sdk/js?client-id=sb'

type ProviderProps = {
  children: ReactNode
}

export type PaymentSelection = {
  value: string
  primaryText: string
}

type ApplicationContext = {
  user: any
  setUser: () => void
  isScriptLoaded: boolean
  isPPSDKLoaded: boolean
  setPPSDKLoaded: (value: boolean) => void
  countrySelection: string
  updateCountrySelection: (value: string) => void
  paymentSelection: PaymentSelection['value']
  updatePaymentSelection: (value: PaymentSelection['value']) => void
  elmoData: any
  Cookies: CookieAttributes
  rightNav: any
  setRightNav: (value: string) => void
  experiments: any
  notificationBanner: any
  nonce: string
  trackEvent: any
  trackRecord: any
  setUserMetaData: () => void
}

export const ApplicationContext = createContext({} as ApplicationContext)

export function useApplicationContext() {
  return useContext(ApplicationContext)
}

const Provider = ({ children }: ProviderProps) => {
  const [user, setUser] = useState({})
  const [isScriptLoaded, setScriptLoaded] = useState(false)
  const [isPPSDKLoaded, setPPSDKLoaded] = useState(false)
  const [countrySelection, setCountrySelection] = useState('US')
  const [paymentSelection, setPaymentSelection] =
    useState<PaymentSelection['value']>('paypal')
  const [cookieBanner, setCookieBanner] = useState({})
  const [experiments, setExperiments] = useState({})
  const [notificationBanner, setNotificationBanner] = useState({})
  const [rightNav, _setRightNav] = useState('')
  const [nonce, setNonce] = useState('')
  const { elmoData } = useApi('/apis/elmodata') as any
  const { pathname } = isBrowser && (window.location as any)

  const setRightNav = (nav: string) => {
    _setRightNav(nav)
  }

  const trackEvent = ({ gaData }: any) => {
    let ga = ReactGA.ga() // eslint-disable-line
    ga('send', 'pageview', gaData)
  }

  /***
   * ReactGA.event(args) - arg is an object with following properties:-
   * args = { category: string, action: string, label: string, value: int, nonInteraction: bool, transport: string }.
   */
  const trackRecord = ({ gaData }: any) => {
    ReactGA.event(gaData) // eslint-disable-line
  }

  const docsBannerInfo = config.docsBannerInfo

  // We had been just using setUser to update state whenever we fetched user
  // data from /apis/user, but we'd also like to update FPTI `cust` property
  // in addition to the user data stored in state. This `updateUser` function
  // allows us to both, and it's this function that we pass down to children
  // as setUser via the Provider value
  const updateUser = (data = {}) => {
    // Sets user data in state which can be consumed by children components who
    // wrap themselves with this <Provider />
    setUser(data)

    // If the user is logged in, we will have their encrypted account number
    // TODO: Type safe for this guy.
    // @ts-ignore
    const { encryptedAccountNumber, accountType } = data

    // If we have an encrypted account number, and if pa.js (FPTI) has already
    // been loaded, then we can just set the `cust` so that it will be
    // populated for all subsequent FPTI events
    // @ts-ignore
    if (encryptedAccountNumber && window?.fpti) {
      // @ts-ignore
      window.fpti.cust = encryptedAccountNumber
      window.fpti.acnt = accountType

      // If pa.js/FPTI has not yet loaded, let's set an event listener, which
      // is set up to be fired when pa.js loads (in dx-analytics.js), and then
      // update the cust property then
    } else if (encryptedAccountNumber) {
      window.addEventListener('paLoaded', async () => {
        // @ts-ignore
        window.fpti.cust = encryptedAccountNumber
        window.fpti.acnt = accountType
      })
    }
  }

  const updateCountrySelection = (country: string) => {
    setCountrySelection(country)
  }

  const updatePaymentSelection = (payment: PaymentSelection['value']) => {
    setPaymentSelection(payment)
  }

  const updateUserMetaData = (data = {}) => {
    // @ts-ignore
    if (data && window?.fpti) {
      // @ts-ignore
      window.fpti.accountNumber =
        data?.accountNumber || data?.encryptedAccountNumber // @ts-ignore
      window.fpti.confidenceScore = data?.confidenceScore
      // @ts-ignore
      window.fpti.identificationType = data?.identificationType
    } else if (data) {
      window.addEventListener('paLoaded', async () => {
        // @ts-ignore
        window.fpti.accountNumber =
          data?.accountNumber || data?.encryptedAccountNumber
        // @ts-ignore
        window.fpti.confidenceScore = data?.confidenceScore
        // @ts-ignore
        window.fpti.identificationType = data?.identificationType
      })
    }
  }

  useEffect(() => {
    ;(async function () {
      if (elmoData) {
        setExperiments({
          isInternalUser: elmoData.isInternalUser ? true : false,
          treatmentIds: JSON.parse(elmoData.treatmentIds || '{}'),
          uiramp: elmoData.uiramp,
          signupramp: elmoData.signupramp,
          integrationBuilder: elmoData.integrationBuilder,
          integrationBuilderKBYC: elmoData.integrationBuilderKBYC,
          aiSearch: elmoData.ai_search,
          aiSearch_acctID:
            elmoData?.ai_search_acctID || 'Ctrl_Developer_AI_Search_AcctID',
          reboot: elmoData.reboot,
          codespaces: elmoData.codespaces,
          homePageRamp: elmoData.homePageRamp,
          serverSdkExperience: elmoData.serverSdkExperience,
          studioEnableMultiparty: elmoData?.studioEnableMultiparty,
        })
      }
      // TODO: we likely don't need to fetch user data here bc we're already
      // doing so in the <Layout /> component, which is rendered on every
      // page load. Also, this useEffect function is likely being called twice,
      // once on initial page load and once when `elmoData` loads/changes
      // @ts-ignore
      if (window && window.app) {
        setScriptLoaded(true)
      } else {
        window.addEventListener('scriptLoad', async () => {
          setScriptLoaded(true)
        })
      }
      try {
        if (!isPPSDKLoaded) {
          await loadScript({ src: PP_SDK_URL })
          setPPSDKLoaded(true)
        }
      } catch (err) {
        // eslint-disable-next-line no-console
        console.log('unable to load PayPal SDK')
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elmoData])

  useEffect(() => {
    //@ts-ignore
    const serverData = (isBrowser && window.__INITIALDATA__) || {}

    setCookieBanner(serverData.cookieBannerInfo)
    if (serverData.attrs) {
      const attrs = JSON.parse(serverData.attrs)
      setNonce(attrs.nonce)
    }

    if (serverData.attrs) {
      const attrs = JSON.parse(serverData.attrs)
      setNonce(attrs.nonce)
    }

    const bannerStr = serverData.unifiedConfig?.notificationBanner
    try {
      setNotificationBanner(bannerStr ? JSON.parse(bannerStr) : {})
    } catch (error) {
      console.error('INVALID Banner String', bannerStr)
    }
    setExperiments({
      isInternalUser: serverData.isInternalUser ? true : false,
      treatmentIds: JSON.parse(serverData.treatmentIds || '{}'),
      uiramp: serverData.uiramp,
      signupramp: serverData.signupramp,
      integrationBuilder: serverData.integrationBuilder,
      integrationBuilderKBYC: serverData.integrationBuilderKBYC,
      reboot: serverData.reboot,
      aiSearch: serverData.ai_search,
      aiSearch_acctID:
        serverData?.ai_search_acctID || 'Ctrl_Developer_AI_Search_AcctID',
      codespaces: serverData.codespaces,
      homePageRamp: serverData.homePageRamp,
      serverSdkExperience: serverData.serverSdkExperience,
      studioEnableMultiparty: serverData?.studioEnableMultiparty,
    })
  }, [])

  // Record page impressions
  useEffect(() => {
    const recordImpression = () => {
      window.PAYPAL.analytics.instance.recordImpression({
        data: {
          space_key: 'SKEXJE',
          e: 'im',
          event_name: `devexp_${window.fpti.page.replace(/[-:]/g, '_')}_shown`,
        },
      })
    }

    const handlePaLoaded = () => {
      recordImpression()
      window.removeEventListener('paLoaded', handlePaLoaded) // Remove the listener
    }

    if (window?.PAYPAL?.analytics?.instance) {
      recordImpression()
    } else {
      window.addEventListener('paLoaded', handlePaLoaded)
    }
  }, [pathname])

  useEffect(() => {
    //@ts-ignore
    const serverData = (isBrowser && window.__INITIALDATA__) || {}

    let bannerStr
    let defaultBannerInfo

    if (pathname.indexOf('/save-payment-methods/') > -1) {
      bannerStr = serverData.unifiedConfig?.notificationBannerDocs
      defaultBannerInfo = docsBannerInfo
    } else if (pathname.includes('/paypal-mobile-checkout/')) {
      defaultBannerInfo = config.docsMobileCheckoutBannerInfo
    } else {
      bannerStr = ''
      defaultBannerInfo = {
        enabled: false,
      }
    }

    try {
      setNotificationBanner(
        bannerStr ? JSON.parse(bannerStr) : defaultBannerInfo,
      )
    } catch (error) {
      console.error('INVALID Banner String', bannerStr)
    }
  }, [pathname])

  ReactGA.initialize(config.googleAnalytics.stock) // eslint-disable-line

  //add elmo data to pass on
  return (
    <ApplicationContext.Provider
      value={{
        user,
        setUser: updateUser,
        setUserMetaData: updateUserMetaData,
        isScriptLoaded,
        isPPSDKLoaded,
        setPPSDKLoaded,
        countrySelection,
        updateCountrySelection,
        paymentSelection,
        updatePaymentSelection,
        elmoData,
        Cookies,
        rightNav,
        setRightNav,
        trackEvent,
        trackRecord,
        experiments,
        notificationBanner,
        nonce,
      }}
    >
      {children}
      <CookieBanner cookieBannerInfo={cookieBanner} />
    </ApplicationContext.Provider>
  )
}

const ProviderComponent = ({ element }: any) => (
  <ThemeProvider theme={PAYPAL_THEME}>
    <Provider>{element}</Provider>
  </ThemeProvider>
)

export default ProviderComponent
