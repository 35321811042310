import React, { useEffect } from 'react'

const CookieBanner = ({ cookieBannerInfo = {} }) => {
  const { html = '', js = '', css = '' } = cookieBannerInfo
  const cookieBanner = decodeURIComponent(`${html}${css}`)
  useEffect(() => {
    let jscriptWithScriptTag = decodeURIComponent(`${js}`)
    const regexMatch = jscriptWithScriptTag.match(
      /nonce="(.*?)">(.*?)<\/script>/,
    )
    const nonceCalculated = regexMatch && regexMatch.length > 1 && regexMatch[1]
    const jscript = regexMatch && regexMatch.length > 2 && regexMatch[2]
    const scriptElement = document.createElement('script')
    scriptElement.nonce = nonceCalculated
    scriptElement.innerHTML = jscript
    document.body.append(scriptElement)

    return () => {
      document.body.removeChild(scriptElement)
    }
  }, [js])

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: cookieBanner,
      }}
    />
  )
}

export default CookieBanner
